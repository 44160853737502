import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';





const initialState = {
    isLoading: true
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
       
    }
})

export default profileSlice.reducer;