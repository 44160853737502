import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import './index.css';
import { themeMode, colorMode, changeLanguage } from '../layoutSlice';
import storage from '../../../services/storage';
import { logout } from '../../../app/commonSlice';
import { userLogout } from '../../login/loginSlice';

const mode_settings = [
    {
        id: 'light',
        name: 'Light',
        background: 'light-background',
        class: 'theme-mode-light'
    },
    {
        id: 'dark',
        name: 'Dark',
        background: 'dark-background',
        class: 'theme-mode-dark'
    }
]

const color_settings = [
    {
        id: 'blue',
        name: 'Blue',
        background: 'blue-color',
        class: 'theme-color-blue'
    },
    {
        id: 'red',
        name: 'Red',
        background: 'red-color',
        class: 'theme-color-red'
    },
    {
        id: 'cyan',
        name: 'Cyan',
        background: 'cyan-color',
        class: 'theme-color-cyan'
    },
    {
        id: 'green',
        name: 'Green',
        background: 'green-color',
        class: 'theme-color-green'
    },
    {
        id: 'orange',
        name: 'Orange',
        background: 'orange-color',
        class: 'theme-color-orange'
    },
]
const language_settings = [
    {
        id: 'en',
        name: 'English',
        icon: ''
    },
    {
        id: 'my',
        name: 'Burmese',
        icon: ''
    }
]

const clickOutsideRef = (content_ref, toggle_ref) => {
    document.addEventListener('mousedown', (e) => {
        // user click toggle
        if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
            content_ref.current.classList.toggle('active')
        } else {
            // user click outside toggle and content
            if (content_ref.current && !content_ref.current.contains(e.target)) {
                content_ref.current.classList.remove('active')
            }
        }
    })
}

const ThemeMenu = () => {

    const menu_ref = useRef(null)
    const menu_toggle_ref = useRef(null)

    clickOutsideRef(menu_ref, menu_toggle_ref)

    const setActiveMenu = () => menu_ref.current.classList.add('active')

    const closeMenu = () => menu_ref.current.classList.remove('active')

    const [currMode, setcurrMode] = useState('light');
    const [currColor, setcurrColor] = useState('blue');
    const [currLang, setCurrLang] = useState(storage.local.getItem('lang') || 'en');

    const dispatch = useDispatch()
    const history = useHistory();

    const setMode = (mode) => {
        setcurrMode(mode.id)
        storage.local.setItem('themeMode', mode.class)
        dispatch(themeMode(mode.class))
    }

    const setColor = (color) => {
        setcurrColor(color.id)
        storage.local.setItem('colorMode', color.class)
        dispatch(colorMode(color.class))
    }

    const setLanguage = (lang) => {
        setCurrLang(lang.id);
        storage.local.setItem('lang', lang.id);
        dispatch(changeLanguage(lang.id))
        i18next.changeLanguage(lang.id)
    }

    useEffect(() => {
        const themeClass = mode_settings.find(e => e.class === storage.local.getItem('themeMode', 'theme-mode-light'));

        const colorClass = color_settings.find(e => e.class === storage.local.getItem('colorMode', 'theme-color-green'));

        if (themeClass !== undefined) setcurrMode(themeClass.id)

        if (colorClass !== undefined) setcurrColor(colorClass.id)

    }, []);

    return (
        <div>
            <button ref={menu_toggle_ref} className="dropdown__toggle" onClick={() => setActiveMenu()}>
                <i className='bx bx-cog'></i>
            </button>
            <div ref={menu_ref} className="theme-menu">
                <h4>Settings</h4>
                <button className="theme-menu__close" onClick={() => closeMenu()}>
                    <i className='bx bx-x'></i>
                </button>
                {/* <div className="theme-menu__select">
                    <span>Choose mode</span>
                    <ul className="mode-list">
                        {
                            mode_settings.map((item, index) => (
                                <li key={index} onClick={() => setMode(item)}>
                                    <div className={`mode-list__color ${item.background} ${item.id === currMode ? 'active' : ''}`}>
                                        <i className='bx bx-check'></i>
                                    </div>
                                    <span>{item.name}</span>
                                </li>
                            ))
                        }
                    </ul>
                </div> */}
                <div className="theme-menu__select">
                    <span>{i18next.t('common.chooseColor')}</span>
                    <ul className="mode-list">
                        {
                            color_settings.map((item, index) => (
                                <li key={index} onClick={() => setColor(item)}>
                                    <div className={`mode-list__color ${item.background} ${item.id === currColor ? 'active' : ''}`}>
                                        <i className='bx bx-check'></i>
                                    </div>
                                    <span>{item.name}</span>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="language-menu__select">
                    <span>{i18next.t('common.chooseLanguage')}</span>
                    <ul className="mode-list">
                        {
                            language_settings.map((item, index) => (
                                <li key={index} onClick={() => setLanguage(item)}>
                                    <div className={`mode-list__color ${item.background} ${item.id === currLang ? 'active' : ''}`}>
                                        <i className='bx bx-check'></i>
                                    </div>
                                    <span>{item.name}</span>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                <Button style={{
                    position: 'absolute', right: 0,
                    bottom: 0, fontWeight: 'bold',
                    width: '100%'
                }} onClick={() => {
                    history.push('/login')
                    dispatch(userLogout());
                    dispatch(logout({deviceId: storage.local.getItem('deviceId')}))
                    storage.session.clear();
                }} icon={<LogoutOutlined />} type="primary">{i18next.t('common.logout')}</Button>
            </div>
        </div>
    )
}

export default ThemeMenu