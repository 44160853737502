import React from 'react';
import { useSelector } from 'react-redux';
import { Tag, Col, Row } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import i18next from 'i18next';
import helper from '../../services/helper';
import Loading from '../loading';

const DetailTrans = () => {
    const transReducer = useSelector(state => state.transHistory);

    const handleCopy = text => {
        navigator.clipboard.writeText(text);
        helper.toast('success', i18next.t('common.copySuccess'));
    }

    return (<>
        {!transReducer.detailTrans.name && <Loading />}
        <Row>
            <Col span={24}>
                <fieldset>
                    <legend>{i18next.t('common.transInfo')}</legend>
                    <Row>
                        <Col span={15}>
                            <Row className="mb-3">
                                <Col span={11} className="label">{i18next.t('common.transName')}:</Col>
                                <Col span={13}>{transReducer.detailTrans?.name}</Col>
                            </Row>

                            <Row className="mb-3">
                                <Col span={11} className="label">{i18next.t('common.transRefId')}:</Col>
                                <Col span={13}>
                                    {transReducer.detailTrans?.transRefId}&nbsp;
                                    <i style={{ cursor: 'pointer' }} onClick={() => handleCopy(transReducer.detailTrans?.transRefId)}><CopyOutlined style={{ fontSize: '15px' }} /></i>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col span={11} className="label">{i18next.t('common.transCode')}:</Col>
                                <Col span={13}>
                                    {transReducer.detailTrans?.code}&nbsp;
                                    <i style={{ cursor: 'pointer' }} onClick={() => handleCopy(transReducer.detailTrans?.code)}><CopyOutlined style={{ fontSize: '15px' }} /></i>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col span={11} className="label">{i18next.t('common.time')}:</Col>
                                <Col span={13}>{moment(transReducer.detailTrans?.createdAt).format('YYYY-MM-DD HH:mm')}</Col>
                            </Row>

                            <Row className="mb-3">
                                <Col span={11} className="label">{i18next.t('common.status')}:</Col>
                                <Col span={13}>
                                    <Tag color={transReducer.detailTrans?.status === 'done' ? '#87d068' : '#b80606'}>
                                        {transReducer.detailTrans?.status}
                                    </Tag>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={9}>
                            <Row className="mb-3">
                                <Col span={11} className="label">{i18next.t('common.amount')}:</Col>
                                <Col span={13}>{helper.toNumberFormat(transReducer.detailTrans?.origAmount)} MMK</Col>
                            </Row>

                            <Row className="mb-3">
                                <Col span={11} className="label">{i18next.t('common.fee')}:</Col>
                                <Col span={13}>{helper.toNumberFormat(transReducer.detailTrans?.fees?.creditFee)} MMK</Col>
                            </Row>

                            <Row className="mb-3">
                                <Col span={11} className="label">{i18next.t('common.discount')}:</Col>
                                <Col span={13}>{helper.toNumberFormat(transReducer.detailTrans?.discount)} MMK</Col>
                            </Row>

                            <Row className="mb-3">
                                <Col span={11} className="label">{i18next.t('common.totalAmount')}:</Col>
                                <Col span={13}>{helper.toNumberFormat(transReducer.detailTrans?.origAmount - (transReducer.detailTrans?.fees?.creditFee + transReducer.detailTrans?.discount))} MMK</Col>
                            </Row>
                        </Col>
                    </Row>
                </fieldset>
            </Col>
        </Row>
        <Row>
            <Col span={12}>
                <fieldset>
                    <legend>{i18next.t('common.processedBy')} {i18next.t('common.information')}</legend>
                    <Row className="mb-3">
                        <Col span={11} className="label">{i18next.t('common.name')}:</Col>
                        <Col span={13}>{transReducer.detailTrans?.processedBy?.name}</Col>
                    </Row>

                    <Row className="mb-3">
                        <Col span={11} className="label">{i18next.t('common.phone')}:</Col>
                        <Col span={13}>{transReducer.detailTrans?.processedBy?.phone}</Col>
                    </Row>
                </fieldset>
            </Col>
            <Col span={12}>
                <fieldset>
                    <legend>{i18next.t('common.sender')} {i18next.t('common.information')}</legend>
                    <Row className="mb-3">
                        <Col span={11} className="label">{i18next.t('common.name')}:</Col>
                        <Col span={13}>{transReducer.detailTrans?.sender?.name}</Col>
                    </Row>

                    <Row className="mb-3">
                        <Col span={11} className="label">{i18next.t('common.phone')}:</Col>
                        <Col span={13}>{transReducer.detailTrans?.sender?.phone}</Col>
                    </Row>
                </fieldset>
            </Col>
        </Row>
        <Row>
            <Col span={12}>
                <fieldset>
                    <legend>{i18next.t('common.shop')} {i18next.t('common.information')}</legend>
                    <Row className="mb-3">
                        <Col span={11} className="label">{i18next.t('common.name')}:</Col>
                        <Col span={13}>{transReducer.detailTrans?.shop?.name}</Col>
                    </Row>

                    <Row className="mb-3">
                        <Col span={11} className="label">{i18next.t('common.code')}:</Col>
                        <Col span={13}>{transReducer.detailTrans?.shop?.code}</Col>
                    </Row>
                </fieldset>
            </Col>
            <Col span={12}>
                <fieldset>
                    <legend>{i18next.t('common.receiver')} {i18next.t('common.information')}</legend>
                    <Row className="mb-3">
                        <Col span={11} className="label">{i18next.t('common.name')}:</Col>
                        <Col span={13}>{transReducer.detailTrans?.receiver?.name}</Col>
                    </Row>

                    <Row className="mb-3">
                        <Col span={11} className="label">{i18next.t('common.phone')}:</Col>
                        <Col span={13}>{transReducer.detailTrans?.receiver?.phone}</Col>
                    </Row>
                </fieldset>
            </Col>
        </Row>
    </>)
}

export default DetailTrans;