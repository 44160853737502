import request from './request';
import helper from './helper';
import config from './config';
const path = {
    clientGetCaptcha: '/api/captcha/getCaptcha',
    login: '/api/party/loginToPortal',
    profile: '/api/party/getProfile',
    partyUpdateCallbackURL: '/api/party/updateCallbackURL',
    getInquiryServiceForParty: '/api/service/getInquiryServiceForParty',
    inquiryResources: '/api/party/inquiryResources',
    logout: '/api/party/partyLogoutToPortal',
};
let pathNotEncrypt = ['clientGetCaptcha', 'login', 'logout', 'profile'] //api not encrypt data
let configs = {}

Object.keys(path).forEach(function (key) {
    configs[key] = async function (data, headers, method) {
        if (data && data.isUpload) {
            let formData = new FormData()
            let blob = await fetch(data.file).then(r => r.blob());
            formData.append('file', blob);
            formData.append('subInfo', data.subInfo)

            return request.upload('/api/upload/uploadImage', formData);
        } else {
            if (!pathNotEncrypt.some(k => k === key)) {
                let dataEncrypt = helper.encryptDataRequest(JSON.stringify(data), config.encryptKey)
                data = {
                    data: dataEncrypt.data,
                    checksum: dataEncrypt.checksum
                }
            }

            return request.send(path[key], data, headers, method)
        }


    }
}, this);

export default configs;