import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../services/api';
import storage from '../services/storage';
export const getS3Url = createAsyncThunk(
    'getS3Url',
    async () => await api.getS3Url()
)

export const getCountry = createAsyncThunk(
    'getCountry',
    async () => await api.getCountry()
)

export const getProvinceByCountry = createAsyncThunk(
    'getProvinceByCountry',
    async (data) => await api.getProvinceByCountry(data)
)

export const getDistrictByProvince = createAsyncThunk(
    'getDistrictByProvince',
    async (data) => await api.getDistrictByProvince(data)
)

export const getTownshipByDistrict = createAsyncThunk(
    'getTownshipByDistrict',
    async (data) => await api.getTownshipByDistrict(data)
)

export const findNricConfig = createAsyncThunk(
    'findNricConfig',
    async () => await api.findConfig({ code: 'nrc' })
)

export const uploadImage = createAsyncThunk(
    'uploadImage',
    async (data) => await api.uploadImage(data)
)

export const logout = createAsyncThunk(
    'logout',
    async (data) => {
        let token = JSON.parse(storage.session.getItem('token'));
        let headers = {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': `${token.token}`
        }
        await api.logout(data);
    }
)
const initialState = {
    s3Url: '',
    countries: [],
    provinces: [],
    districts: [],
    townships: [],
    nricConfig: { nrcStDivCodes: [], nrcTspCodes: [], nrcTypes: [] },
    uploadSuccess: false
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        clearProvince: (state) => { state.provinces = [] },
        clearDistrict: (state) => { state.districts = [] },
        clearTownship: (state) => { state.townships = [] }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getS3Url.fulfilled, (state, action) => {
                state.s3Url = action.payload.link;
            })
            .addCase(getCountry.fulfilled, (state, action) => {
                state.countries = action.payload.data;
            })
            .addCase(getProvinceByCountry.fulfilled, (state, action) => {
                state.provinces = action.payload.data;
            })
            .addCase(getDistrictByProvince.fulfilled, (state, action) => {
                state.districts = action.payload.data;
            })
            .addCase(getTownshipByDistrict.fulfilled, (state, action) => {
                state.townships = action.payload.data;
            })
            .addCase(findNricConfig.fulfilled, (state, action) => {
                let nrcStDivCodes = JSON.parse(action.payload.data.subConfigs.NRC_CODES.value)
                let nrcTspCodes = JSON.parse(action.payload.data.subConfigs.CODE_TOWNSHIP.value)
                let nrcTypes = JSON.parse(action.payload.data.subConfigs.NRC_TYPES.value)
                if (nrcStDivCodes.length > 0) {
                    nrcStDivCodes = nrcStDivCodes.map(obj => {
                        return { id: obj };
                    })
                }
                if (nrcTypes.length > 0) {
                    nrcTypes = nrcTypes.map(obj => {
                        return { id: obj };
                    })
                }

                state.nricConfig = { nrcStDivCodes, nrcTspCodes, nrcTypes }
            })
            .addCase(uploadImage.pending, (state, action) => {
                state.uploadSuccess = false
            })
            .addCase(uploadImage.fulfilled, (state, action) => {
                state.uploadSuccess = true
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.logoutSuccess = true
            })
    }
})

export const { clearProvince, clearDistrict, clearTownship } = commonSlice.actions;

export default commonSlice.reducer;