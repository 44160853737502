import { configureStore } from '@reduxjs/toolkit';
import layoutReducer from '../features/layouts/layoutSlice';
import userReducer from '../features/login/loginSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import transHistoryReducer from '../features/transHistory/transHistorySlice';
import profileReducer from '../features/profile/profileSlice';

import commonReducer from './commonSlice';

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    user: userReducer,
    dashboard: dashboardReducer,
    transHistory: transHistoryReducer,
    profile: profileReducer,
    common: commonReducer,
  },
});
