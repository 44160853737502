import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Modal, Form, Input } from 'antd';
import CardStatus from '../../components/cardStatus';
import storage from '../../services/storage';
import helper from '../../services/helper';
import { setShowModal, partyUpdateCallbackURL } from './dashboardSlice';
import i18next from 'i18next';
import { getInquiryServiceForParty } from '../transHistory/transHistorySlice';
import config from '../../services/config';

const Dashboard = () => {
    const user = JSON.parse(storage.session.getItem('user'));
    const listBalance = Object.keys(user.balance).map(x => ({ key: x, amount: user.balance[x].amount }))

    const dashboardSlice = useSelector(state => state.dashboard);
    const dispatch = useDispatch();
    const [callbackHost, setCallbackURL] = useState(user.partyConfig?.partyHost);
    const [IP, setIP] = useState(user.partyConfig?.ip);

    const [encryptKey, setEncryptKey] = useState(config.encryptKey);  //config.encryptKey
    const [isShowModal, setModalHide] = useState(false);

    useEffect(() => {
        if ((user.partyConfig?.type === 'demo' || user.type === 'demo') && !dashboardSlice.isShow1st && !storage.session.getItem('isShow1st')) {
            dispatch(setShowModal(true));
            setModalHide(true);
            storage.session.setItem('isShow1st', true)
        }
        dispatch(getInquiryServiceForParty());
        if (config.encryptKey) {
            setEncryptKey(config.encryptKey)
        }
    }, [])

    return (
        <div>
            <h2 className="page-header">Dashboard</h2>
            <Modal title={i18next.t('common.callbackSetting')} className="text-center"
                visible={isShowModal} maskClosable={false}
                onOk={() => {
                    if (!callbackHost || !IP || !encryptKey) {
                        return helper.toast('error', i18next.t('required_desc.callbackSetting'))
                    }
                    if (!helper.checkEncryptKey(encryptKey)) {
                        return helper.toast('error', i18next.t('required_desc.encryptKey'))
                    }

                    dispatch(partyUpdateCallbackURL({ callbackHost, ip: IP, encryptKey })).then(() => {
                        setModalHide(false)
                        helper.toast('success', i18next.t('common.updatedSuccessfully'));
                    })
                }}
                onCancel={() => {
                    if (!callbackHost || !IP) {
                        return helper.toast('error', i18next.t('required_desc.callbackSetting'))
                    }
                    setModalHide(false)
                }}
                okText="Confirm" >
                <Form layout="vertical" name="callback-setting-verification-form" initialValues={{ callbackHost: callbackHost, ip: IP, encryptKey }}>
                    <Form.Item
                        required
                        label={i18next.t('common.callbackHost')}
                        name="callbackHost">
                        <Input placeholder={i18next.t('common.callbackHost')} onChange={e => setCallbackURL(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        required
                        label={i18next.t('common.encryptKey')}
                        name="encryptKey">
                        <Input placeholder={i18next.t('common.encryptKey')} onChange={e => setEncryptKey(e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        required
                        label={`${i18next.t('common.ip')} (${i18next.t('common.ipNote')})`}
                        name="ip">
                        <Input type='text' placeholder={i18next.t('common.ip')} value={IP} onChange={e => setIP(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
            <Row>
                <Col md={7} sm={24} >
                    {listBalance.map((x, key) => <CardStatus key={key} icon={"bx bx-dollar"} count={`${helper.toNumberFormat(x.amount)} ${x.key}`} title={"Current Balance"} />)}
                </Col>
            </Row>
        </div>
    )
}

export default Dashboard
