import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

export const partyUpdateCallbackURL = createAsyncThunk(
    'partyUpdateCallbackURL', (data) => {
        try {
            return api.partyUpdateCallbackURL(data)
        } catch (error) {
            console.log('partyUpdateCallbackURL: ', error)
        }
    }
);

const initialState = {
    isShow1st: false
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setShowModal: (state, action) => {
            state.isShow1st = action.payload
        }
    },
    extraReducers: (builder) => {

    }
})

export const { setShowModal } = dashboardSlice.actions;

export default dashboardSlice.reducer;