// import { useHistory } from 'react-router-dom';
import axios from 'axios';
import config from './config';
import helper from './helper';
import storage from './storage';

const instance = axios.create({
    baseURL: config.host,
    timeout: 30000
})

const request = {

    upload: (url, formData) => {
        // let cf = {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //         'Authorization': `Bearer ${storage.session.getItem('token')}`,
        //         'deviceId': storage.local.getItem('deviceId')
        //     }
        // }
        // return new Promise((resolve, reject) => {
        //     instance.post(url, formData, cf).then(rs => resolve(rs)).catch(err => reject(err))
        // })
    },

    send: (url, data, headers, method = 'post') => {
        if (!headers) {
            let token = JSON.parse(storage.session.getItem('token'))
            headers = {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token.token}`
            }
        }
        if (data) data.deviceId = storage.local.getItem('deviceId')
        return new Promise((resolve, reject) => {
            instance[method](url, data, { headers }).then(async rs => {
                if (rs.data.err === 0 || rs.data.code === 0 || rs.data.err === 200) {
                    resolve(rs.data);
                } else {
                    if (rs.data.err === 8013) {
                        let refreshToken = JSON.parse(storage.session.getItem('refreshToken'))
                        let token = JSON.parse(storage.session.getItem('token'))
                        let dataRq = {
                            token: token.token,
                            refreshToken: refreshToken.token,
                            deviceId: storage.local.getItem('deviceId') || ""
                        }
                        headers = {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `${config.THIRD_PARTY_TOKEN}`
                        }
                        await instance[method](`/api/party/refreshToken`, dataRq, { headers }).then(res => {
                            const response = res.data;
                            if (response.err === 0 || response.code === 0 || response.err === 200) {
                                storage.session.setItem('token', JSON.stringify(response.token));
                                window.location.href = '/#/dashboard';
                            }
                            else {
                                helper.toast('error', response.message)
                                storage.session.clear();
                                window.location.href = '/#/login';
                            }
                        }).catch(error => {
                            console.log(error)
                            storage.session.clear();
                            window.location.href = '/#/login';
                        });
                    }
                    else helper.toast('error', rs.data.message);
                }
            }).catch(err => {
                helper.toast('error', err.response?.data?.message || err.message);
                if (err.response?.data.err === 8013) {
                    storage.session.clear();
                    window.location.href = '/#/login';
                }
                reject(err)
            })
        })
    }
}

export default request;