import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import { Layout, Menu } from 'antd';

import './index.css';
import logo from '../../../assets/images/logo_small.png';
import storage from '../../../services/storage';

const { Sider } = Layout;
const { SubMenu } = Menu;


const Sidebar = () => {
    const history = useHistory();

    const [collapsed, setCollapsed] = useState(false);

    var _nav = [
        {
            name: i18next.t('menu.dashboard'),
            icon: 'bx bxs-dashboard',
            route: '/dashboard'
        },
        {
            name: i18next.t('menu.profile'),
            icon: 'bx bx-user',
            route: '/profile'
        },
        {
            name: i18next.t('menu.report'),
            icon: 'bx bx-bar-chart-alt-2',
            subMenu: [
                {
                    name: i18next.t('menu.transactionHistory'),
                    route: '/transaction-history'
                }
            ]
        }
    ]

    return (
        <Sider className="sidebar" collapsible collapsed={collapsed} onCollapse={(e) => setCollapsed(e)}>
            <div className="sidebar__logo">
                {/* <img src={logo} alt="company logo" /> */}
                <h2>{i18next.t('common.partnerPortal')}</h2>
            </div>
            <Menu mode="inline">
                {
                    _nav.map((parent, index) => {
                        if (!parent.subMenu) {
                            return (
                                <Menu.Item className="sidebar__item-inner" icon={<i className={parent.icon}></i>}
                                    key={parent.name + index}
                                    onClick={() => history.push(parent.route)}>
                                    <span className="label">{parent.name}</span>
                                </Menu.Item>
                            )
                        } else {
                            return (
                                <SubMenu key={parent.name}
                                    icon={<i className={parent.icon}></i>}
                                    title={<span className="label">{parent.name}</span>}>
                                    {parent.subMenu.map((child, idx) => (
                                        <Menu.Item onClick={() => history.push(child.route)}
                                            key={child.name + idx}>
                                            <span className="label">{child.name}</span>
                                        </Menu.Item>
                                    ))}
                                </SubMenu>
                            )
                        }
                    })
                }
            </Menu>
        </Sider>
    )
}

export default Sidebar