import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

export const inquiryResources = createAsyncThunk(
    'inquiryResources', (data) => api.inquiryResources(data)
)

export const getInquiryServiceForParty = createAsyncThunk(
    'getInquiryServiceForParty', () => api.getInquiryServiceForParty()
)


const initialState = {
    data: [],
    count: 0,
    inquiryService: null
}

export const transHistorySlice = createSlice({
    name: 'transHistory',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(inquiryResources.fulfilled, (state, action) => {
                state.data = action.payload.data;
                state.count = action.payload.total;
            })
            .addCase(getInquiryServiceForParty.fulfilled, (state, action) => {
                state.inquiryService = action.payload.data[0];
            })
    }
})



export default transHistorySlice.reducer;