import React from 'react';
import ReactDOM from 'react-dom';
// css
// import './index.css';
import './assets/boxicons-2.0.7/css/boxicons.min.css';
import './assets/css/grid.css';
import './assets/css/theme.css';
import './assets/css/index.css';
import 'antd/dist/antd.css';

import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';

// import * as serviceWorker from './serviceWorker';

document.title = 'Payment Party Portal';

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
