import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { osVersion, osName, browserName, fullBrowserVersion, } from "react-device-detect";
import api from '../../services/api';
import config from '../../services/config';
import helper from '../../services/helper';
import storage from '../../services/storage';

const initialState = {
    user: {},
    objCaptcha: {},
    isLogIn: false,
    showOTPForm: false,
    otpId: '',
    token: '',

};

export const clientGetCaptcha = createAsyncThunk(
    'clientGetCaptcha',
    () => {
        try {
            let device = `${browserName} version ${fullBrowserVersion} (${osName};${osVersion})`
            let data = {
                deviceId: device,
                userAgent: device,
                type: 'partner-login'
            }
            let headers = {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${config.THIRD_PARTY_TOKEN}`
            }
            storage.local.setItem('deviceId', device)
            return api.clientGetCaptcha(data, headers);
        } catch (error) {
            console.log('clientGetCaptcha: ', error)
        }
    }
);



export const clientRequestLogin = createAsyncThunk(
    'clientRequestLogin', async (dataInput, { dispatch }) => {
        try {
            let headers = {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${config.THIRD_PARTY_TOKEN}`
            }
            let { data, checksum } = helper.encryptDataRequest(JSON.stringify({ code: dataInput.code, password: dataInput.password }), config.BASIC_ENCRYPT_KEY)
            delete dataInput.code
            delete dataInput.password
            let result = await api.login(Object.assign({}, dataInput, { data, checksum }), headers)
            let profileResult = await api.profile(null, { 'Authorization': `Bearer ${result.token.token}` });

            // result.config

            dispatch(logInSuccess({ token: result.token, refreshToken: result.refreshToken, user: profileResult.data, config: result.config }));
        } catch (error) {
            console.log(error);
            dispatch(clientGetCaptcha());
        }
    }
);

export const clientVerifyLogin = createAsyncThunk(
    'clientVerifyLogin', async (data) => {
        try {
            let headers = {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${config.THIRD_PARTY_TOKEN}`
            }
            return await api.verifyLogin(data, headers)
        } catch (error) {
            console.log('clientVerifyLogin: ', error)
        }
    }
);


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        closeOTPPopup: (state) => {
            state.showOTPForm = false
            state.otpId = ''
        },
        userLogout: (state) => {
            state.user = {}
            state.objCaptcha = {}
            state.isLogIn = false
            state.showOTPForm = false
            state.otpId = ''
            state.token = {}
        },
        logInSuccess: (state, action) => {
            storage.session.setItem('token', JSON.stringify(action.payload.token))
            storage.session.setItem('refreshToken', JSON.stringify(action.payload.refreshToken))
            storage.session.setItem('user', JSON.stringify(action.payload.user))

            state.user = action.payload.user
            state.objCaptcha = {}
            state.isLogIn = true
            helper.setEncryptKey(action.payload.config)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(clientGetCaptcha.fulfilled, (state, action) => {
                state.objCaptcha = action.payload.data;
            })
        // .addCase(clientRequestLogin.fulfilled, (state, action) => {
        //     if (action.payload) {
        //         storage.session.setItem('token', JSON.stringify(action.payload.token))
        //         storage.session.setItem('refreshToken', JSON.stringify(action.payload.refreshToken))


        //         // state.objUser = userInfo
        //         // state.tokenMcPt = tokenMcPt
        //         // state.token = token
        //         state.objCaptcha = {}
        //         state.isLogIn = true
        //     }
        // })
    },
})


export const { closeOTPPopup, userLogout, logInSuccess } = userSlice.actions;

export default userSlice.reducer;