import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Space, Form, Input, Row, Col, Select, DatePicker, Button, Modal } from 'antd';
import { SearchOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import moment from 'moment';

import storage from '../../services/storage';
import helper from '../../services/helper';
import { getInquiryServiceForParty, inquiryResources } from './transHistorySlice';
import DetailTrans from './detailTrans';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

const columns = [
    {
        title: '#',
        key: '#',
        dataIndex: 'no',
        width: 60,
        fixed: 'left'
    },
    {
        title: i18next.t('common.transactionId'),
        dataIndex: 'transactionId',
        key: 'transactionId',
        width: 250
    },
    {
        title: i18next.t('common.transRefId'),
        key: 'transRefId',
        dataIndex: 'transRefId',
        width: 250
    },
    {
        title: i18next.t('common.senderId'),
        key: 'senderId',
        dataIndex: 'senderId'
    },
    {
        title: i18next.t('common.senderUserId'),
        key: 'senderUserId',
        dataIndex: 'senderUserId'
    },
    {
        title: i18next.t('common.receiverId'),
        key: 'receiverId',
        dataIndex: 'receiverId'
    },
    {
        title: i18next.t('common.receiverUserId'),
        key: 'receiverUserId',
        dataIndex: 'receiverUserId'
    },
    {
        title: i18next.t('common.amount'),
        key: 'amount',
        dataIndex: 'amount',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.transAmount'),
        key: 'transAmount',
        dataIndex: 'transAmount',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    }
]
// const statusList = [{ name: "done" }, { name: "refunded" }, { name: "pending" }, { name: "cancelled" }, { name: "failed" }, { name: "reversed" }, { name: "expired" }, { name: "refused" }, { name: "redeemed" }];

const TransHistory = () => {
    const user = JSON.parse(storage.session.getItem('user'));
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const transHistoryRedeucer = useSelector(state => state.transHistory);

    const [data, setData] = useState([]);
    const [search, setSearch] = useState({});
    const [pagination, setPagination] = useState({ position: ["bottomLeft"] });
    const [isLoading, setLoading] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    useEffect(() => {
        if (transHistoryRedeucer.inquiryService) {
            fetchData();
        } else {
            dispatch(getInquiryServiceForParty())
        }
    }, [dispatch])

    var skip = 0, limit = 10;
    const fetchData = async (args, isExport) => {
        try {
            setLoading(true);
            if (args) {
                skip = (parseInt(args.current) - 1) * parseInt(args.pageSize)
                limit = parseInt(args.pageSize)
            }

            let objSearch = {}
            if (search.length) {
                search.forEach(item => {
                    objSearch[item.name[0]] = item.value
                })

                if (objSearch.createdAt) {
                    objSearch.startDate = moment(objSearch.createdAt[0]).format("YYYY-MM-DD")
                    objSearch.endDate = moment(objSearch.createdAt[1]).format("YYYY-MM-DD")
                    delete objSearch.createdAt
                }
            }
            objSearch.serviceId = transHistoryRedeucer.inquiryService.id
            const params = {
                ...objSearch,
                skip: skip,
                limit: limit
            }

            // if (isExport) {
            //     let exported = await dispatch(exportTransactions({ ...params, limit: -1 })).unwrap();
            //     setLoading(false);
            //     window.open(exported.link, '_blank')
            // }

            const result = await dispatch(inquiryResources(params)).unwrap();

            setData(helper.assignOrderNumber(result.data, skip));
            setPagination({ ...pagination, total: result.total || result.data.length || 0 });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    // let colsKey = columns.map(c => c.key);
    // if (!colsKey.includes('action')) {
    //     columns.push({
    //         title: i18next.t('common.action'),
    //         key: 'action',
    //         fixed: 'right',
    //         width: 100,
    //         render: (text, record) => (
    //             <Space size="middle" onClick={() => {
    //                 dispatch(detailTrans({ id: record.id }));
    //                 setShowDetail(true);
    //             }}>
    //                 <Button type="primary"><InfoCircleOutlined /> {i18next.t('common.detail')}</Button>
    //             </Space>
    //         )
    //     })
    // }

    return (
        <div>
            <h2 className="page-header">{i18next.t('menu.transactionHistory')}</h2>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Form form={form}
                                layout="vertical"
                                onFinish={value => fetchData()}
                                onFieldsChange={(changedValues, allValues) => setSearch(allValues)}>
                                <Row>
                                    {/* <Col span={6}>
                                        <Form.Item name="serviceId" label={i18next.t('common.services')} >
                                            <Select allowClear={true}
                                                placeholder={i18next.t('common.services')}>
                                                {
                                                    transHistoryRedeucer.services.map((item, index) => <Select.Option key={`${index}_trans_name`} value={item.id}>{item.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col> */}
                                    <Col span={6}>
                                        <Form.Item name="createdAt" label={i18next.t('common.createdAt')}>
                                            <RangePicker format={dateFormat} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={19}></Col>
                                    <Col span={5}>
                                        <Form.Item>
                                            <Button className="mr-2" icon={<SearchOutlined />} type="default" htmlType="submit">{i18next.t('common.search')}</Button>
                                            {/* <Button onClick={() => fetchData('', true)} icon={<DownloadOutlined />} type="default" htmlType="button">{i18next.t('common.export')}</Button> */}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>

                            {/* <Modal title={i18next.t('common.transDetailInfo')} className="text-center"
                                visible={showDetail || Boolean(transHistoryRedeucer.detailTrans.name)} footer={null} className="modal-detail-transaction"
                                onCancel={() => {
                                    setShowDetail(false)
                                    dispatch(clearTransDetail({}))
                                }}>
                                <DetailTrans />
                            </Modal> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <label htmlFor="" style={{ float: "right", fontSize: "larger", fontWeight: "bold" }}>Count: {pagination.total}</label>
                            <Table columns={columns} dataSource={data} className="table-wrapper" loading={isLoading}
                                pagination={false} scroll={{ y: 500 }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransHistory;