import { notification } from 'antd';
import i18next from 'i18next';
import Swal from 'sweetalert2';
import { cloneDeep } from 'lodash';
import CryptoJS from 'crypto-js';
import config from './config';
const helper = {}
helper.toast = (type, message) => {
    notification[type]({
        message: message,
        duration: 2.5
    })
}

helper.toNumberFormat = (value = 0) => {
    return Number(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

helper.numberOnly = (event) => {
    // const pattern = /[0-9]/;
    // const inputChar = String.fromCharCode(event.charCode);

    // if (!pattern.test(inputChar)) {
    //     helper.toast('error', i18next.t('required_desc.numberOnly'));
    //     event.preventDefault();
    // }
}

helper.confirm = (title) => {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: title,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t('common.ok')
        }).then((result) => {
            if (result.isConfirmed) {
                resolve(result);
            }
        })
    })
}

helper.confirmWithRemark = (title) => {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: title,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t('common.ok'),
            input: 'textarea',
            inputPlaceholder: i18next.t('common.remark')
        }).then((result) => {
            if (result.isConfirmed) {
                resolve(result);
            }
        })
    })
}

helper.mappingField = (obj, field) => {
    let nestedFields = field.split('.');
    let source = obj;
    while (nestedFields.length > 1) {
        let parentField = nestedFields.shift();
        source[parentField] = source[parentField] || {};//assign if existed
        source = source[parentField];

    }
    let leafField = nestedFields.shift();

    return source[leafField];
}

helper.validateFields = (fields, objMapping) => {
    let flag = false;
    if (fields) {
        fields.forEach(objField => {
            let value = helper.mappingField(objMapping, objField.fieldName);
            if (!value) {
                flag = true
                helper.toast('error', `${objField.isChoose ? i18next.t('common.pleaseChoose') : i18next.t('common.pleaseInput')} ${i18next.t(objField.errorMsg)}`);
            }
        })
        return flag;
    }
    return flag;
}

helper.generateString = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

helper.checkIPAddress = (value) => {
    let flag = true;
    let ipRegex = new RegExp(`^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\\/(\\d|[1-2]\\d|3[0-2]))?$`);
    if (!ipRegex.test(value)) {
        flag = false
    }
    return flag;
}

helper.assignOrderNumber = (data, skip) => {
    let dataCopy = cloneDeep(data);
    dataCopy.forEach((item, index) => item.no = index + 1 + skip);
    return dataCopy;
}

helper.renameKey = (obj, old_key, new_key) => {
    if (obj[old_key])
        if (old_key !== new_key) {
            Object.defineProperty(obj, new_key,
                Object.getOwnPropertyDescriptor(obj, old_key));
            delete obj[old_key];
        }
}

helper.capitalizeFirstLetter = (string) => {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
helper.SHA256 = (raw) => {
    return CryptoJS.SHA256(raw).toString(CryptoJS.enc.Hex);
}

helper.encryptAES = (raw, key) => {
    try {
        let encryptKey = CryptoJS.enc.Utf8.parse(key);
        let rawData = CryptoJS.AES.encrypt(raw, encryptKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString()
        return rawData
    } catch (error) {
        console.log('encryptAES', error.message)
        return ''

    }

}
helper.decryptAES = (data, key) => {
    try {
        let decryptKey = CryptoJS.enc.Utf8.parse(key);
        var rawData = CryptoJS.AES.decrypt(data, decryptKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString(CryptoJS.enc.Utf8)
        return rawData;
    } catch (e) {
        return ''
    }
}

helper.encryptDataRequest = (raw, key) => {
    try {
        let checksum = helper.SHA256(raw)
        let data = raw
        if (key) data = helper.encryptAES(raw, key)
        return { data, checksum }

    } catch (error) {
        return { err: 1, data: '', checksum: '' }
    }
}

helper.checkEncryptKey = (key) => {
    if (!key) return false
    let dataTest = { _id: 100, value: 3000 }
    try {
        let data = helper.decryptAES(helper.encryptAES(JSON.stringify(dataTest), key), key)
        data = JSON.parse(data)
        if (data && Object.keys(data).length === Object.keys(dataTest).length && data._id === dataTest._id) {
            return true
        }
        return false
    } catch (error) {
        return false
    }
}






helper.setEncryptKey = (data) => {
    let configs = helper.decryptAES(data, config.ENCRYPT_PARTY_PORTAL)
    try {
        configs = JSON.parse(configs)
    } catch (error) {
        configs = { encryptKey: '' }
    }
    config.encryptKey = configs.encryptKey
    config.TYPE_PARTNER = configs.type
}



export default helper;