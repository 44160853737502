const storage = {
    local: {
        setItem: (name, value) => localStorage.setItem(name, value),
        getItem: (name) => localStorage.getItem(name),
        removeItem: (name) => localStorage.removeItem(name)
    },
    session: {
        setItem: (name, value) => sessionStorage.setItem(name, value),
        getItem: (name) => sessionStorage.getItem(name),
        removeItem: (name) => sessionStorage.removeItem(name),
        clear: () => sessionStorage.clear()
    }
}

export default storage;