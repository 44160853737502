import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Layout from './features/layouts';
import Login from './features/login';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <HashRouter>
        <Switch>
          <Route path='/login' exact name='Login Page' component={Login} />
          <Route path='/' name="Layout" component={Layout} />
        </Switch>
      </HashRouter>
    </I18nextProvider>
  );
}

export default App;
