import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import i18next from 'i18next';

import storage from '../../services/storage';
import InfoDetails from './inforDetails';
const Profile = () => {
    const user = JSON.parse(storage.session.getItem('user'));
    const dispatch = useDispatch();

    useEffect(() => {
        
    }, [dispatch])

    return (
        <div>
            <h2 className="page-header">{i18next.t('menu.profile')}</h2>

            <div className="row">
                <div className="col-12">
                    <div className="card" style={{height:'650px'}}>
                        <div className="card__body">
                            <Row className="mt-5">
                                <Col span={24}>
                                    <Row>
                                        <InfoDetails user={user} />
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Profile;