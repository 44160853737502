import React from 'react';
import { Col, Row, Tag, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import i18next from 'i18next';
import moment from 'moment';

const InfoDetails = ({ user }) => {

    return (
        <>
            <Col sm={24} md={6}>
                <Row>
                    <img className="circle-avatar" src={user.link + user.avatar} alt="avatar" />
                </Row>
            </Col>

            <Col sm={24} md={9}>
                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.type')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.type || '-'}</Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.code')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.code || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.name')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.name || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.dob')}</Col>
                    <Col sm={24} md={13} xs={13}>{moment(user.birth).format('DD/MM/YYYY') || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.email')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.email || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.maritalStatus')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.maritalStatus || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.kycType')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.kycType || '-'}</Col>
                </Row>

                {user.kycType === 'newNric' && <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.nricNumber')}</Col>
                    <Col sm={24} md={13} xs={13}>{`${user.nric?.code}/${user.nric?.name}/${user.nric?.number}` || '-'}</Col>
                </Row>}

                {user.kycType === 'oldNric' && <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.nricNumber')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.oldNric || '-'}</Col>
                </Row>}

                {user.kycType === 'passport' && <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.passportInfo')}</Col>
                    <Col sm={24} md={13} xs={13}>{`${user.passportInfo?.countryCode}/${user.passportInfo?.passportNo}` || '-'}</Col>
                </Row>}

                {user.kycType === 'passport' && <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.expiredDate')}</Col>
                    <Col sm={24} md={13} xs={13}>{`${user.passportInfo?.expireDate}` || '-'}</Col>
                </Row>}

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.fatherName')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.ownerInfo?.fatherName || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.motherName')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.ownerInfo?.motherName || '-'}</Col>
                </Row>
            </Col>

            <Col sm={24} md={9}>
                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.status')}</Col>
                    <Col sm={24} md={13} xs={13}><Tag color={user.status === 'active' ? '#87d068' : '#b80606'}>{user.status}</Tag></Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.gender')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.gender || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.country')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.country?.name || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.region')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.province?.name || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.district')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.district?.name || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.township')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.township?.name || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.address')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.address || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.latidude')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.lat || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.longitude')}</Col>
                    <Col sm={24} md={13} xs={13}>{user.long || '-'}</Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={24} md={11} xs={11} className="label">{i18next.t('common.createdAt')}</Col>
                    <Col sm={24} md={13} xs={13}>{moment(user.createdAt).format('DD/MM/YYYY HH:mm') || '-'}</Col>
                </Row>
            </Col>
        </>
    )
}

export default InfoDetails;