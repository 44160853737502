import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './dashboard';
import TransHistory from './transHistory';
import Profile from './profile';

import Loading from './loading';

const Routes = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                <Route path='/dashboard' exact component={Dashboard} />
                <Route path='/transaction-history' component={TransHistory} />
                <Route path='/profile'  component={Profile} />
            </Switch>
        </Suspense>
    )
}

export default Routes
