import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { my, en } from './locales';
import storage from '../services/storage';

const currentLang = storage.local.getItem('lang') || 'en';

const options = {
    interpolation: {
        escapeValue: false, // not needed for react!!
    },

    debug: false,

    resources: {
        my: {
            common: my,
        },
        en: {
            common: en,
        },
    },

    fallbackLng: currentLang,

    ns: ['common'],

    defaultNS: 'common',

    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    },
};

i18n
    .use(LanguageDetector)
    .init(options)
// .changeLanguage('en', (err, t) => {
//     if (err) return console.log('something went wrong loading', err);
// });

export default i18n;